<template>
  <div id="shuttle_ticket_page">
    <div class="container">
      <span class="page-title">Shuttle Ticket</span>
      <div class="qrcode-image">
        <img :src="`https://api.qrserver.com/v1/create-qr-code/?size=600x600&data=${domain}/crp?crp=191D12345`" alt="">
      </div>

      <div class="ticket-information">
        <span class="shuttle-information-title">PICK-UP LOCATION</span>
        <span class="shuttle-information-desc">12 Burton Hall, Sandyford</span>

        <span class="shuttle-information-title">PICK-UP DATE</span>
        <span class="shuttle-information-desc">Today at 16:30</span>

        <span class="shuttle-information-title">DROP-OFF LOCATION</span>
        <span class="shuttle-information-desc">Dart Station, Greystones</span>

        <span class="shuttle-information-title">DROP-OFF DATE</span>
        <span class="shuttle-information-desc">Today at 17:10</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: { type: String }
  },

  data: () => ({
    publicPath: process.env.BASE_URL,
    domain: process.env.VUE_APP_DOMAIN
  })
}
</script>

<style lang="scss" scoped>
  #shuttle_ticket_page {
    .qrcode-image {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 5px;

      img {
        width: 100%;
        max-width: 280px;
        max-height: 280px;
        opacity: 0.8;
        border: 1px solid rgba(0,0,0,0.1);
        box-shadow: 2px 2px 2px rgba(0,0,0,0.1);
        padding: 20px;
      }
    }

    .ticket-information {
      margin-top: 20px;
      span { display: block; }

      .shuttle-information-title {
        margin-top: 20px;
        font-weight: 500;
      }

      .shuttle-information-desc {
        font-size: 16px;
      }
    }
  }
</style>